/* eslint-disable camelcase */
import ignoredMessages from './ignoredMessages';

const isMessageIgnored = (messageIgnored, errorMessage) => errorMessage.indexOf(messageIgnored) !== -1;

const shouldIgnoreError = error => ignoredMessages.filter(
  ignored => isMessageIgnored(ignored, error.message),
).length > 0;

const errorHandler = error => shouldIgnoreError(error);

export default errorHandler;
