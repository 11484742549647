/**
 * startApp dependency
 */
const startApp = require('../startApp');

/**
 * Main component
 */
const HomeView = require('../../pages/home/components/HomeView');

startApp(HomeView);
