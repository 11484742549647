const ignoredMessages = [
  // Ignoring SessionStorage messages is not an error that affects users and is generating false alerts.
  'Failed to read the \'sessionStorage\' property from \'Window\': Access is denied for this document.',
  // March 2024 - General increase of error rate across meli frontends due to this error, ignore it by the time
  'Script error.',
  // April 2024 - Meta (Instagram) browser error reported, safe to ignore by now
  'Can\'t find variable: _AutofillCallbackHandler',
];

export default ignoredMessages;
