module.exports = {
  REORDER_LAYOUT_COMPONENT: 'APPEARANCE/REORDER_LAYOUT',
  MOVE_COMPONENT_UP: 'APPEARANCE/MOVE_UP',
  MOVE_COMPONENT_DOWN: 'APPEARANCE/MOVE_DOWN',
  MOVE_TO_COMPONENT: 'APPEARANCE/MOVE_TO_COMPONENT',
  RESET_LAYOUT: 'APPEARANCE/RESET_LAYOUT',
  SAVE_LAYOUT: 'APPEARANCE/SAVE_LAYOUT',
  TRIGGER_ANIMATION_DOWN: 'TRIGGER_ANIMATION/DOWN',
  TRIGGER_ANIMATION_UP: 'TRIGGER_ANIMATION/UP',
  UPDATE_COMPONENT_PROPS: 'APPEARANCE/UPDATE_COMPONENT_PROPS',
  SET_COMPONENT_HIDDEN: 'APPEARANCE/SET_COMPONENT_HIDDEN',
  REMOVE_LAYOUT_COMPONENT: 'APPEARANCE/REMOVE_COMPONENT',
  LAYOUT_REQUEST: 'APPEARANCE/LAYOUT_REQUEST',
  LAYOUT_REQUEST_SUCCESS_FULL: 'APPEARANCE/LAYOUT_REQUEST_SUCCESS_FULL',
  LAYOUT_REQUEST_SUCCESS_PARTIAL: 'APPEARANCE/LAYOUT_REQUEST_SUCCESS_PARTIAL',
  LAYOUT_REQUEST_ERROR: 'APPEARANCE/LAYOUT_REQUEST_ERROR',
  CHANGE_DEVICE: 'APPEARANCE/CHANGE_DEVICE',
  CHANGE_OSNAME: 'APPEARANCE/CHANGE_OSNAME',
  ADD_LAYOUT_COMPONENT: 'APPEARANCE/ADD_LAYOUT_COMPONENT',
  UPDATE_LAYOUT: 'APPEARANCE/UPDATE_LAYOUT',
  SECTION_EDITING: 'SECTION_EDITING',
  SET_LOADING: 'SET_LOADING',
  SHOULD_UPDATE_DEVICE: 'SHOULD_UPDATE_DEVICE',
  SET_RICH_TEXT_VALUE: 'SET_RICH_TEXT_VALUE',
  SET_CCP_TEMPLATE: 'SET_CCP_TEMPLATE',
  SHOW_MENU_NAVIGATION: 'APPEARANCE/SHOW_MENU_NAVIGATION',
  CURRENT_CATEGORY_SELECTED: 'APPEARANCE/CURRENT_CATEGORY_SELECTED',
};
