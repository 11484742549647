const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const { Button } = require('@andes/button');
const VisibilityOFF24 = require('@andes/icons/VisibilityOFF24');

const wordings = require('./wordings');
const { useRenderDispatcher, useRenderContext } = require('../../pages/home/context');

const DisabledWrapper = ({
  children,
  componentId,
  i18n,
}) => {
  const { sectionDisabledText, button } = wordings(i18n);
  const renderActions = useRenderDispatcher();
  const { draftMode } = useRenderContext();
  const handleClick = () => {
    renderActions.setComponentHidden(componentId, false);
  };

  const classNameHidden = classNames('hidden-wrapper', {
    'ui-ms-hidden-wrapper--draft-mode': draftMode,
  });

  return (
    <div className={classNameHidden}>
      <div className="hidden-wrapper__children-container">
        {children}
      </div>
      <div className="hidden-wrapper__content-container">
        <VisibilityOFF24 />
        <p className="hidden-wrapper__text">{sectionDisabledText}</p>
        <Button className="hidden-wrapper__button" onClick={handleClick}>
          {button.text}
        </Button>
      </div>
    </div>
  );
};

DisabledWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  i18n: PropTypes.shape().isRequired,
};

module.exports = DisabledWrapper;
