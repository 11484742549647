const MelidataTracker = require('@mshops-web-core/melidata-tracker').default;
const MeliGaTracker = require('@mshops-web-core/meli-ga-tracker').default;

const trackMelidataEvent = (melidataEvent, commonTrackData) => {
  if (melidataEvent) {
    const { path, event_data: eventData } = melidataEvent;
    let melidataEventData = eventData;

    if (commonTrackData) {
      melidataEventData = eventData
        ? { ...eventData, ...commonTrackData }
        : commonTrackData;
    }

    MelidataTracker.notify({
      path,
      data: melidataEventData,
      type: melidataEvent.type || 'event',
    });
  }
};

const trackAnalyticsEvent = (analyticsEvent) => {
  if (analyticsEvent) {
    const {
      category,
      action,
      label,
      path,
      type,
      page,
      dimensions: customDimensions,
    } = analyticsEvent;

    if (type === 'view') {
      MeliGaTracker.sendViewTrack({
        path,
        customDimensions,
      });
    } else {
      MeliGaTracker.sendEventTrack({
        page,
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
      });
    }
  }
};

const trackEvent = (track, commonTrackData) => {
  if (track) {
    const {
      melidata_event: melidataEvent,
      analytics_event: analyticsEvent,
    } = track;

    trackMelidataEvent(melidataEvent, commonTrackData);
    trackAnalyticsEvent(analyticsEvent);
  }
};

const trackAndRedirect = (href, track, commonTrackData) => {
  trackEvent(track, commonTrackData);

  window.location.assign(href);
};

module.exports = {
  trackEvent,
  trackAndRedirect,
};
